<template>

<app-item route="Item" :id="item.id" id-name="item" :query="(isByGroup) ? {group: $route.params.group} : false" :selected="selected" v-on:shiftclick="$emit('shiftclick')">

	<app-item-badge :route="(category.id) ? 'Category' : 'Category.Groups'" :params="{category: category.id}" :image="(category.id) ? category.icon : false" :icon="category.icon" :color="category.background" :tooltip="category.title" />

	<app-item-badge v-if="!isByGroup" route="Group" :params="{group: group.id}" :image="(group.id) ? group.image : false" :tooltip="group.title" />

	<app-item-badge :image="item.image" :excluded="item.exclude" :invisible="!item.visible" />

	<app-item-title :text="$highlight(item.title, highlight)" />

	<app-item-drag v-if="canOrder" />

</app-item>

</template>

<script>

export default {

	props: ['item', 'highlight', 'canOrder', 'selected'],

	computed: {

		isByGroup: function() {

			return this.$route.meta.byGroup

		},

		group: function() {

			return (this.item.group) ? this.$store.getters['groups/find'](this.item.group) : false

		},

		category: function() {

			return (this.group) ? this.$store.getters['categories/find'](this.group.category) : false

		}

	}

}

</script>

<style scoped>

</style>
